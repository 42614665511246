body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-field{
  padding: 10px;
}

.errorMsg{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
  
}

.table-header{
  background-color:#FAF9F6;
  display: flex;
  align-items: center;
  padding: 0px 10px;
   
  
}
.select_items{
  width: 100% !important;
}
 
.edited-header{
   margin-left: 9.5%;
    margin-bottom: 2%;
    font-size: 20px;
    font-weight: bold;
}
@media only screen and (max-width: 768px) {
 
  .table-header{
    
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-bottom: 15px;
    padding-top: 15px; 
  }
  .edited-header{
    margin-left: 6%;
     margin-bottom: 2%;
     font-size: 20px;
     font-weight: bold;
 }

  .select_items{
    width: 320px !important;
  }
  .input-field{
     width: 90%;
    
  }
  .search-container{
    width: 95%; 
     

  }
  
  
  
  
  }
 